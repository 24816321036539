import {ActionButton} from "../../../styled_components/ActionButton";
import {IoIosClose} from "react-icons/io";
import React, {useEffect, useReducer, useState} from "react";
import {TextP, Title1, Title2} from "../../../styled_components/General";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectTable, selectVendor, selectVendorSchedule, setVendorSchedule} from "../../vendor/vendorSlice";
import {Translate} from "../../intl/translator";
import {TableInfo} from "../../../styled_components/Menu";
import {selectCart} from "../cartSlice";
import {Cart} from "../Cart";
import {CartScreenItem} from "./CartScreenItem";
import {CartItem} from "../CartItem";
import {ProductScreen} from "../../menu/components/ProductScreen/ProductScreen";
import {CartStickyFooter} from "../../../styled_components/Cart";
import {AppButton} from "../../../styled_components/Core";
import {useSessionGuard} from "../../session/hooks";
import {canUserOrder} from "../../menu/menuService";
import {getVendor, getVendorSchedule} from "../../vendor/vendorRepository";
import {useNavigate} from "react-router-dom";
import {VisibilityStatusGuard} from "../../vendor/components/VisibilityStatusGuard";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {CartComments} from "./CartComments";
import {selectUser} from "../../user/userSlice";
import {createRequest, navigateToTips } from "../cartRepository";
import { Order } from "../Order";
import {selectSession} from "../../session/sessionSlice";
import CONFIG from "../../../app/config";
import {UserStatus} from "../../user/userInterface";
import { Vendor } from "../../vendor/Vendor";
// PhoneConfirmation Component
// type PhoneConfirmationProps = {
//     phoneNumber?: string; // Optional to handle missing phone numbers
//     onAddPhone: (newPhone: string) => void; // Callback for adding a new phone
// };

// const PhoneConfirmation: React.FC<PhoneConfirmationProps> = ({ phoneNumber, onAddPhone }) => {
//     const [confirmed, setConfirmed] = useState(false);
//     const [countryCode, setCountryCode] = useState("+357"); // Default to US country code
//     const [localNumber, setLocalNumber] = useState(""); // Empty initial phone number
//     const [hasPhoneNumber, setHasPhoneNumber] = useState(false); // Track if phone number is added
//     const vendor = useAppSelector(selectVendor);
//     const user = useAppSelector(selectUser);
//     // Initialize state with user's phone number if available
//     useEffect(() => {
//         if (phoneNumber) {
//             const [code, number] = phoneNumber.split(" "); // Assuming phoneNumber is in the format "+1 22345678"
         
            
//             setCountryCode(code); // Set the country code from user's phoneNumber
//             setLocalNumber(number); // Set the local number from user's phoneNumber
//             setHasPhoneNumber(true); // Mark that phone number has been set
//         }
//     }, [phoneNumber]); // Runs only when phoneNumber changes

//     const handleConfirm = () => {
//         if (countryCode && localNumber) {
//             setConfirmed(true);
//             localStorage.setItem("notifyNumber", countryCode+localNumber);
//             alert(`Phone number ${countryCode} ${localNumber} confirmed!`);
//         } else {
//             alert("Please make sure the phone number is correctly filled.");
//         }
//     };

//     const handleAddPhone = () => {
//         // console.log("test "+countryCode+ " "+ localNumber );
//         handleConfirm();
//         if (localNumber) {
//             setHasPhoneNumber(true); // Mark that phone number is added
//             onAddPhone(`${countryCode} ${localNumber}`);
//             // setLocalNumber(""); // Reset local number input after adding
//         } else {
//             alert("Please enter a valid phone number.");
//         }
//     };

//     const formatPhoneNumber = (phone: string) => {
//         const [code, number] = phone.split(" ");
//         return `${code} ${number}`;
//     };

//     return (
//         <div className="p-4 border rounded bg-gray-50 shadow-md">
//             {hasPhoneNumber ? (
//                 <div>
//                     <p className="text-lg">
//                     Confirm your number to receive notifications about your order status: 
//                     </p>
//                     <input
//                         style={{width: "70px"}}
//                             type="text"
//                             className="border px-3 py-2 rounded focus:ring focus:outline-none"
//                             placeholder="Country Code (e.g. +1)"
//                             value={countryCode}
//                             onChange={(e) => setCountryCode(e.target.value)} // Update country code
//                         />
//                         <input
//                             type="text"
//                             className="border px-3 py-2 rounded focus:ring focus:outline-none"
//                             placeholder="Enter your phone number"
//                             value={localNumber}
//                             onChange={(e) => setLocalNumber(e.target.value)} // Update local number
//                         />
//                     {!confirmed ? (
//                         <button
//                             className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
//                             onClick={handleConfirm}
//                         >
//                             Confirm
//                         </button>
//                     ) : (
//                         <p className="mt-2 text-green-600">Thank you for confirming your phone number!</p>
//                     )}
//                 </div>
//             ) : (
//                 <div>
//                     <p className="text-red-600">No phone number available. Please add your phone number:</p>
//                     <div className="mt-2 flex gap-2">
//                         <input
//                         style={{width: "70px"}}
//                             type="text"
//                             className="border px-3 py-2 rounded focus:ring focus:outline-none"
//                             placeholder="Country Code (e.g. +1)"
//                             value={countryCode}
//                             onChange={(e) => setCountryCode(e.target.value)} // Update country code
//                         />
//                         <input
//                             type="text"
//                             className="border px-3 py-2 rounded focus:ring focus:outline-none"
//                             placeholder="Enter your phone number"
//                             value={localNumber}
//                             onChange={(e) => setLocalNumber(e.target.value)} // Update local number
//                         />
//                         <button
//                          style={{background: '#'+vendor.Color}}
//                             className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
//                             onClick={handleAddPhone}
//                         >
//                             Add
//                         </button>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };
export function CartScreen() {
    useSessionGuard();
    const strings = {
        s_cart: Translate('cart'),
        s_table: Translate('table'),
        s_order_items: Translate('order_items'),
        s_empty_cart: Translate('cart_is_empty'),
        s_cart_screen_description: Translate('cart_screen_description'),
        s_checkout: Translate('checkout'),
        s_service_hours: Translate('service_hours')
    };
    const table = useAppSelector(selectTable);
    const cartSliceState = useAppSelector(selectCart);
    const vendor = useAppSelector(selectVendor);
    const user = useAppSelector(selectUser);
    const scheduleHours = useAppSelector(selectVendorSchedule);
    const navigate = useNavigate();
    const [cart, setCart] = useState<Cart>(new Cart({items: []}));
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();
    const [shopOpen, setShopOpen] = useState(true)
    const cartI = useAppSelector(selectCart);
    const session = useAppSelector(selectSession);
    const handleAddPhone = (newPhone: string) => {
        // Handle the logic to update the user's phone number in the state or backend.
        // console.log("New phone added:", newPhone);
    };
    useEffect(() => {
        Promise.all([
            getVendor(vendor.OutletID),
            getVendorSchedule(vendor.OutletID)
        ]).then(res => {
            dispatch(setVendorSchedule(res[1]));
            // console.log("cart vendor status:"+vendor.OpenStatus?.Status);

                      if (!vendor.OpenStatus?.Status){
                navigate("/menu")
            }
            setShopOpen(vendor.OpenStatus?.Status ?? false);
            setLoading(false);
        })
    }, [dispatch, vendor.OutletID])

    useEffect(() => {
        setCart(new Cart(cartSliceState));
    },[cartSliceState])

 
    const checkout = () => {
        if (
            !canUserOrder({user, vendor})
        ){
            localStorage.setItem("intent", "/checkout");
            navigate("/login")
        }else{
            if (vendor.EnableTips != "1"){
                navigate("/checkout")
            }else{
            // 
                let order: Order = new Order(cartI);
                order.info.Tableid = parseInt(table?.TableID ?? "1");
                order.info.Scantimestamp = session.sst.toString();
                order.info.Outletid = parseInt(vendor.OutletID);
                order.info.Reguserid = user.Verified !== UserStatus.Verified ? CONFIG.GUEST_USER_ID : user.RegisteredUserID;
                createRequest(order, '2',(cart.calcCartPrice() / 100).toFixed(2).toString()).then((res) => {
                    // const pastOrder: PastOrderI = {
                    //     VendorId: vendor.OutletID,
                    //     OrderId: res.OrderID
                    // }
                    // console.log("RequestID: "+res.OrderID);
                    navigateToTips(res.OrderID);
            
                    // document.location.replace("/order");
                }).catch(e => navigate('/order/failed'))
            }
        }
    }

    return (
        <div id="cart-screen" className="min-h-screen">
            <div className="grid py-5 indent items-center content-center grid-cols-2 grid-rows-1 mb-4">
                <div className="col-start-1 col-span-1 row-start-1">
                    <ActionButton onClick={() => { navigate("/menu") }}>
                        <IoIosClose size="32" />
                    </ActionButton>
                </div>
                <h1 className="text-center row-start-1 col-start-1 col-span-full text-3xl fore-color">
                    {strings.s_cart}
                </h1>
            </div>
    
            <div className="flex indent place-content-between py-5">
                <div>
                    <h2 className="text-3xl fore-color">{strings.s_order_items}</h2>
                    <TextP>{strings.s_cart_screen_description}</TextP>
                </div>
                <div>
                    {table &&
                        <TableInfo align={"end"} padding={"15px 0 10px"} size={16}>
                            {strings.s_table} #<span>{table.TableName}</span>
                        </TableInfo>
                    }
                </div>
            </div>
            <hr className="py-3" />
    
            <div className="indent pb-8">
                {cart.Items.map(item => {
                    const cartItem = new CartItem(item);
                    return <CartScreenItem key={item.Id} item={cartItem} />;
                })}
                {cart.Items.length === 0 &&
                    <p className="text-2xl text-gray-300 text-center">{strings.s_empty_cart}</p>
                }
            </div>
    
            <div className="indent pb-8">
                <hr className="py-3" />
                <div className="flex place-content-between items-center content-center">
                    <div>
                        <Title2 className="inline-block pr-2" color={"var(--theme-modded-fore-color)"}>Total</Title2>
                        <TextP className="inline-block">
                            ({cart.itemsCount()} item{cart.itemsCount() > 1 && "s"})
                        </TextP>
                    </div>
                    <Title1>{cart.displayFormattedPrice()}</Title1>
                </div>
            </div>
    
            <div className="indent pb-5">
                {vendor.GNotes === "1" && <CartComments />}
            </div>
    
            <VisibilityStatusGuard showInVisibilityStatuses={[]}>
                <div className="flex flex-col items-center">
                    {vendor.GNotes !== "1" && (
                        <div className="indent pb-5">
                            {/* Spacer for when CartComments is not shown */}
                        </div>
                    )}
                    <CartStickyFooter enabled={cart.Items.length > 0}>
                        {loading ? (
                            <div className="flex justify-center items-center pt-8 pb-4">
                                <AiOutlineLoading3Quarters
                                    size={40}
                                    className="animate-spin m-auto"
                                    color="var(--theme-primary-color)"
                                />
                            </div>
                        ) : (
                            <AppButton
                                disable={!shopOpen}
                                onClick={() => {
                                    if (shopOpen) {
                                        checkout();
                                    }
                                }}
                            >
                                {shopOpen ? strings.s_checkout : strings.s_service_hours + ' ' + scheduleHours.working_hours}
                            </AppButton>
                        )}
                    </CartStickyFooter>
                </div>
            </VisibilityStatusGuard>
    
            <ProductScreen />
        </div>
    );
}
