import styled from "styled-components";

interface CartStickyFooterI {
    enabled?: boolean
}

export const CartStickyFooter = styled.div<CartStickyFooterI>`
  position: fixed;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  z-index: 3;
  bottom: ${props => props.enabled ? '0' : '-1000px'};
  display: flex;
  background-color: var(--theme-modded-sticky-bg);
  align-items: center;
  justify-content: center;
  will-change: transform; /* Hint for GPU acceleration */
  @media only screen and (min-width: 700px) {
    max-width: 450px;
  }
`;


interface CartCheckoutButtonContainerI {
    disable?: boolean
}

export const CartCheckoutButtonContainer = styled.div<CartCheckoutButtonContainerI>`
  background-color: ${props => props.disable ? 'grey' : "var(--theme-primary-color)"};
  height: 54px;
  margin-bottom: 15px;
  border-radius: 27px;
  min-width: 230px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

export const CheckoutMethodItem = styled.div`
  background: var(--theme-modded-card-bg);
  box-shadow: var(--theme-shadow);
  min-height: 70px;
  display: flex;
  padding: 15px;
  align-items: stretch;
  margin-top: 20px;
  .payment-logo{
    max-width: 50px;
  }
  .payment-divider{
    border-left: solid thin var(--theme-modded-fore-color);
    margin: 0 15px;
  }
  .payment-description{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    font-weight: 400;
    font-size: 18px;
    color: var(--theme-modded-fore-color);
    small{
      font-size: 14px;
      margin-top: 5px;
      color: gray;
    }
  }
  .payment-arrow{
    width: 22px;
    display: flex;
    align-items: center;
  }
`;

export const OrderScreenHeader = styled.div`
  //background: var(--theme-primary-color);
  background: ${props => props.color ? props.color : "var(--theme-primary-color)"};
  min-height: 300px;
  width: 90%;
  border-radius: 0 0 50% 50%;
  margin: -175px auto 30px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 50px;
  flex-direction: column;
  color: white;
  font-size: 20px;
`
