export interface VendorInterface {
    OutletID: string,
    Name: string,
    EstablishmentName?: string,
    EstablismentAddress?: string,
    EstablismentPhoneNumber?: string,
    CompanyNumber?: string,
    TypeName?: string,
    CurrencySymbol?: string,
    OutleTypeID?: number,
    EstablismentID?: number,
    CurrencyID?: number,
    SubscriptionPlanID?: number,
    IsActive?: number,
    Description?: string,
    Address?: string,
    Country?: string,
    City?: string,
    PostalCode?: string,
    AcceptedOrdersTimeLimit?: string,
    OrdersDelayTime?: string,
    Email?: string,
    PhoneNumber?: string,
    FaxNumber?: string,
    URL?: string,
    FileName?: string,
    CoverImage?: string,
    GoogleLongitudeCoord?: string,
    GoogleLatitudeCoord?: string,
    Logo?: string,
    IsSecretKeyNeeded?: string,
    HasRooms?: number,
    SecretKey?: string,
    Disabled?: string,
    IsOpen?: string,
    Is24hOpen?: string,
    AllowGuestUsers?: string,
    OrderSystemFlag?: number,
    EnrollmentDate?: string,
    SubscriptionDate?: string,
    SubscriptionEndDate?: string,
    OpeningTime?: string,
    ClosingTime?: string,
    ClosingSoonTime?: string,
    OpeningTimestamp?: string,
    DisableOrders?: number,
    PriceDisabled?: number,
    OrderSystem?: number,
    OperationID?: string,
    VisibilityOption?: string,
    AlohaStoreID?: string,
    HasSchedule?: number,
    HasSendToKitchen?: number,
    SeperateChecks?: number,
    outletFileName?: string,
    Color?: string,
    SplashImage?: string,
    OpenStatus?: VendorOpenStatusI,
    FNotes?: string,
    BevNotes?:string,
    GNotes?:string,
    EnableTips?:string,
    EnableLastOrders?:string,
    NotifyUsers?:string
     

}

export interface VendorButtonInterface {
    ItemID: string,
    Name: string
}

export interface VendorScheduleInterface {
    working_hours: string,
    Service_hours: string,
    ClosingSoon: string
}

export interface QrDetailsInterface {
    QrID: string,
    ReferenceId: string,
    OutletID: string,
    TableID: string,
    GroupQR: string,
    SecretKey: string,
}

export interface QrOutletInterface {
    OutletID: string,
    TypeName: string,
    CoverImage: string,
    Name: string
}

export interface EstablishmentInterface {
    Address: string,
    City: string,
    Country: string,
    CoverImage: string,
    Description: string,
    EstablismentID: string,
    FaxNumber: string,
    Name: string,
    OutletID: string,
    PhoneNumber:string,
    TypeName: string,
    URL: string,
    LogoFileName: string,
    TableID: string,
    Outlets: Array<QrOutletInterface>
    Color: string
}

export interface EstablishmentCategoryInterface {
    name: string,
    Outlets: Array<QrOutletInterface>
}

export interface TableInfoInterface {
    TableID: string,
    TableTypeID: string,
    TableNo: string,
    TableName: string,
    NumOfSeats?: string,
    TypeName: string,
}

export interface ShopOpenStatusInterface {
    Color: string,
    Description: string
}

export interface ShopMapUrlInterface {
    isSet: boolean,
    url: string
}

export interface  VendorLoadDataInterface {
    id: string,
    table: string
}

export interface VendorPaymentMethodI {
    OutletID: string,
    PaymentTypeID: string,
    AccountID?: string,
    SortOrder?: string,
    Disable?: string,
    SendEmailToUsers?: string,
    HasPromptMessage?: string,
    PromptMessage?: string,
    PaymentTypeName?: string
}

export interface VendorCompanyI {
    CompanyOutletID: string,
    OutletID: string,
    CompanyID: string,
    Name: string,

}

export interface VendorOpenStatusI {
    ClosingSoon: boolean,
    DeliveryStatus: boolean,
    Status: boolean,
}

export enum VendorVisibility {
    OutletInformationOnly = "1",
    MenuNoPrices = "2",
    MenuWithPrices = "3",
    Hidden = "5"
}


export enum VendorOperation {
    Disable = "1",
    MenuOnly = "2",
    DigitalOrdering = "3"
}
